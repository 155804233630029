export const VARSITY = 'Varsity';
export const JUNIOR_VARSITY = 'Junior Varsity';

export enum TEAM_LEVEL {
  varsity = 'varsity',
  jv = 'jv',
}

export enum EMAIL_TYPE {
  athleticDirector = 'athleticDirector',
  crew = 'crew',
}

export const DAY_FORMAT = 'dddd';
export const DATE_FORMAT = 'MMMM Do';
export const TIME_FORMAT = 'h:mma';

export const MINUTES = 'minutes';

export const LIGHT = 'light';
export const DARK = 'dark';

export const FULL_UNIFORM_STATEMENT =
  "Please bring both long and short sleeve shirts. We'll decide what shirt we'll wear prior to taking the field.";
