import React, { Dispatch, FC, SetStateAction } from 'react';

import { FormControl, FormLabel, Switch } from '@chakra-ui/react';

type UniformStatementSwitchesProps = {
  addUniformStatement: boolean;
  setAddUniformStatement: Dispatch<SetStateAction<boolean>>;
};

const UniformStatementSwitches: FC<UniformStatementSwitchesProps> = ({
  addUniformStatement,
  setAddUniformStatement,
}) => {
  return (
    <FormControl display="flex" alignItems="center">
      <FormLabel htmlFor="add-uniform-statement" mb="0">
        Add uniform statement?
      </FormLabel>
      <Switch
        id="add-uniform-statement"
        isChecked={addUniformStatement}
        onChange={(e) => setAddUniformStatement(e.target.checked)}
      />
    </FormControl>
  );
};

export default UniformStatementSwitches;
