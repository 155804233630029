import moment from 'moment/moment';

import { DATE_FORMAT, DAY_FORMAT, MINUTES, TEAM_LEVEL, TIME_FORMAT } from './constants';

export const prettyPrintLevel = (level: TEAM_LEVEL): string =>
  level === TEAM_LEVEL.varsity ? 'Varsity' : 'Junior Varsity';

export const timeSettings = {
  [TEAM_LEVEL.jv]: {
    fieldTime: 30,
    pregameTime: null,
    arrivalTime: 45,
  },
  [TEAM_LEVEL.varsity]: {
    fieldTime: 30,
    pregameTime: 60,
    arrivalTime: 75,
  },
};

export const getTimeOffsets = (selectedDate, level: TEAM_LEVEL) => {
  const timeOffsets = timeSettings[level];
  const fieldTime = moment(selectedDate)
    .subtract(timeOffsets.fieldTime, MINUTES)
    .format(TIME_FORMAT);
  const pregameTime = moment(selectedDate)
    .subtract(timeOffsets?.pregameTime || 0, MINUTES)
    .format(TIME_FORMAT);
  const arrivalTime = moment(selectedDate)
    .subtract(timeOffsets.arrivalTime, MINUTES)
    .format(TIME_FORMAT);

  return { fieldTime, pregameTime, arrivalTime };
};

export const parseDateTime = (date) => {
  return {
    day: date.format(DAY_FORMAT),
    date: date.format(DATE_FORMAT),
    time: date.format(TIME_FORMAT),
  };
};
