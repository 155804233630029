import React, { FC, useState } from 'react';

import { ChakraProvider } from '@chakra-ui/react';
import { createTheme, THEME_ID } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import MainForm from './components/MainForm/MainForm';
import { TEAM_LEVEL } from './utils/constants';
import { theme as chakraTheme } from './theme';

import './App.css';

const App: FC = () => {
  const [level, setLevel] = useState<TEAM_LEVEL>(TEAM_LEVEL.varsity);

  const muiTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  return (
    <ChakraProvider theme={{ ...chakraTheme, [THEME_ID]: muiTheme }} resetCSS>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <div className="App">
          {/*<AppHeader level={level} setLevel={setLevel} />*/}
          <MainForm level={level} setLevel={setLevel} />
        </div>
      </LocalizationProvider>
    </ChakraProvider>
  );
};

export default App;
