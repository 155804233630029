import React, { useEffect, useState } from 'react';

import { EMAIL_TYPE, TEAM_LEVEL } from '../../../utils/constants';
import { EmailGenerator, emailGenerators } from '../../../utils/emailContent';

import CopyableTextArea from './CopyableTextArea';

type EmailTextAreaProps = {
  selectedDate: any;
  level: TEAM_LEVEL;
  emailType: EMAIL_TYPE;
  school: string;
  athleticDirector: string;
  addUniformStatement?: boolean;
};

const EmailTextArea: React.FC<EmailTextAreaProps> = ({
  selectedDate,
  level,
  emailType,
  school,
  athleticDirector,
  addUniformStatement,
}) => {
  const [emailText, setEmailText] = useState<string>('');

  const emailGenerator: EmailGenerator = emailGenerators[level][emailType];

  useEffect(() => {
    const generatedEmailText = emailGenerator({
      athleticDirector,
      selectedDate,
      school,
      level,
      addUniformStatement,
    });

    setEmailText(generatedEmailText);
  }, [
    athleticDirector,
    emailGenerator,
    selectedDate,
    level,
    emailType,
    school,
    addUniformStatement,
  ]);

  return <CopyableTextArea initialValue={emailText} />;
};

export default EmailTextArea;
