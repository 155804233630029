import React, { FC } from 'react';

import { Button, Flex, Textarea, useClipboard } from '@chakra-ui/react';

type CopyableTextAreaProps = {
  initialValue: string;
  handleChange?: (input: string) => void;
};

const CopyableTextArea: FC<CopyableTextAreaProps> = ({ initialValue, handleChange }) => {
  const { hasCopied, onCopy, setValue, value } = useClipboard(initialValue);

  const handleTextChange = (e) => {
    const updatedText = e?.target?.value;
    if (handleChange) handleChange(updatedText);
    setValue(updatedText);
  };

  return (
    <Flex mb={2}>
      <Textarea value={value} placeholder="Welcome" onChange={handleTextChange} size="md" />
      <Button onClick={onCopy} ml={2}>
        {hasCopied ? 'Copied' : 'Copy'}
      </Button>
    </Flex>
  );
};

export default CopyableTextArea;
