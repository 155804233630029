import React, { Dispatch, FC, SetStateAction } from 'react';
import moment from 'moment';
import { Moment } from 'moment/moment';

import { Center, HStack } from '@chakra-ui/react';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';

type DateTimePickersProps = {
  selectedDate: moment.Moment;
  setSelectedDate: Dispatch<SetStateAction<moment.Moment>>;
};

const DateTimePickers: FC<DateTimePickersProps> = ({ selectedDate, setSelectedDate }) => {
  const handleDateChange = (date: moment.Moment | null) => {
    setSelectedDate(date as unknown as Moment);
  };

  return (
    <Center>
      <HStack>
        <DatePicker
          value={selectedDate}
          onChange={handleDateChange}
          className="picker-text-field"
        />
        <TimePicker
          value={selectedDate}
          onChange={handleDateChange}
          className="picker-text-field"
        />
      </HStack>
    </Center>
  );
};

export default DateTimePickers;
