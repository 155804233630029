import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import moment, { Moment } from 'moment';

import { Box, Center, FormControl, FormLabel, Input, Stack, StackItem } from '@chakra-ui/react';

import { EMAIL_TYPE, TEAM_LEVEL } from '../../utils/constants';

import DateTimePickers from './components/DateTimePickers';
import EmailTextArea from './components/EmailTextArea';
import Header from './components/Header';
import UniformStatementSwitches from './components/UniformStatementSwitches';

import './MainForm.css';

type MainFormProps = {
  level: TEAM_LEVEL;
  setLevel: Dispatch<SetStateAction<TEAM_LEVEL>>;
};

const MainForm: FC<MainFormProps> = ({ level, setLevel }) => {
  const [selectedDate, setSelectedDate] = useState<Moment>(moment());
  const [school, setSchool] = useState<string>('Richmond Senior High School');
  const [athleticDirector, setAthleticDirector] = useState<string>('Mr. Smith');
  const [addUniformStatement, setAddUniformStatement] = useState<boolean>(true);

  return (
    <Center id="center">
      <Box id="email-form-container" p={4} mt="10px">
        <Stack spacing={4}>
          <StackItem>
            <Header level={level} setLevel={setLevel} />
          </StackItem>
          <StackItem>
            <DateTimePickers selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
          </StackItem>
          <StackItem>
            <UniformStatementSwitches
              addUniformStatement={addUniformStatement}
              setAddUniformStatement={setAddUniformStatement}
            />
          </StackItem>
          <StackItem>
            <FormControl>
              <FormLabel>Athletic Director</FormLabel>
              <Input
                type="text"
                value={athleticDirector}
                onChange={(e) => setAthleticDirector(e.target.value)}
              />
            </FormControl>
          </StackItem>
          <StackItem>
            <FormControl>
              <FormLabel>School Name</FormLabel>
              <Input type="text" value={school} onChange={(e) => setSchool(e.target.value)} />
            </FormControl>
          </StackItem>
          <StackItem>
            <FormControl>
              <FormLabel>Athletic Director Email</FormLabel>
              <EmailTextArea
                selectedDate={selectedDate}
                level={level}
                emailType={EMAIL_TYPE.athleticDirector}
                school={school}
                athleticDirector={athleticDirector}
              />
            </FormControl>
          </StackItem>
          <StackItem>
            <FormControl>
              <FormLabel>Crew Email</FormLabel>
              <EmailTextArea
                selectedDate={selectedDate}
                level={level}
                emailType={EMAIL_TYPE.crew}
                school={school}
                athleticDirector={athleticDirector}
                addUniformStatement={addUniformStatement}
              />
            </FormControl>
          </StackItem>
        </Stack>
      </Box>
    </Center>
  );
};

export default MainForm;
