import React, { Dispatch, FC, SetStateAction } from 'react';

import { Button, Card, CardBody } from '@chakra-ui/react';

import { TEAM_LEVEL } from '../../../utils/constants';
import { prettyPrintLevel } from '../../../utils/utils';

type HeaderProps = {
  level: TEAM_LEVEL;
  setLevel: Dispatch<SetStateAction<TEAM_LEVEL>>;
};

const Header: FC<HeaderProps> = ({ level, setLevel }) => {
  const handleTeamLevelChange = () => {
    setLevel(level === TEAM_LEVEL.jv ? TEAM_LEVEL.varsity : TEAM_LEVEL.jv);
  };

  return (
    <Card mb={5}>
      <CardBody pt={'10px'} pb={'10px'}>
        <Button
          fontSize="3xl"
          fontWeight="bold"
          onClick={handleTeamLevelChange}
          style={{ textDecoration: 'none' }}
          variant="link"
        >
          {prettyPrintLevel(level)}
        </Button>
      </CardBody>
    </Card>
  );
};

export default Header;
